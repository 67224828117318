import React from 'react';

import './style.scss';
import { FaGithub, FaInstagram, FaLinkedin, FaBehance } from 'react-icons/fa';
import { motion } from 'framer-motion';

function Footer() {
  return (
    <div>
      <motion.div class="footer">
        <div className="box-footer">
          <div className="logo wrapper">
            <h2>REACTSLAYER.</h2>
            <p>&copy; 2021 Reactslayer Dmitriy Balaban</p>
          </div>
          <div className="contact wrapper">
            <p>reactslayer@gmail.com</p>
            <p>+38 093 382 87 67</p>
          </div>
          <div className="adress wrapper">
            <p>
              Ukraine <br /> Kiev
            </p>
          </div>
          <div className="social-icons wrapper">
            <p>You can follow me on</p>
            <ul>
              <li>
                <a
                  target="blank"
                  rel="noopener noreferrer"
                  href="https://github.com/sakulads"
                  className="social-icon"
                >
                  <FaGithub />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/indtpslayer/"
                  className="social-icon"
                >
                  <FaInstagram />
                </a>
              </li>
              {/* <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.behance.net/Ghofranebh"
                  className="social-icon"
                >
                  <FaBehance />
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default Footer;
