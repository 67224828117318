import React from 'react';
import './About.scss';

// import Timeline from "../../components/Timeline/Timeline";

import ProfileDescription from '../../components/ProfileDescription';

function About() {
  return (
    <div>
      <div className="about">
        <div className="container">
          <span className="background">About Me.</span>
          <div className="wrapper">
            <ProfileDescription />
            <div className="skils">
              <div className="skils-section">
                <h3>Design</h3>
                <h2>
                  Web & mobile
                  <p>&#8725;</p>Landing pages<p>&#8725;</p>E-commerce projects
                  <p>&#8725;</p> User interface<p>&#8725;</p>Animation
                  <p>&#8725;</p>
                  Photography<p>&#8725;</p>
                </h2>
              </div>
              <div className="skils-section">
                <h3>Technologies</h3>
                <h2>
                  Javascript <p> &#8725; </p> React.js <p>&#8725;</p>Next.js
                  <p>&#8725;</p>
                  Wordpress <p>&#8725;</p>
                  Three.js <p> &#8725; </p> Html5 <p>&#8725;</p>
                  CSS3 <p> &#8725; </p> Sass <p> &#8725; </p> GIT
                </h2>
              </div>
            </div>
            {/* <div className="timeline">
              <Timeline />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
