import React from "react";
import "../react.scss";

const Reactslayer = () => {
  return (
    <svg
      height="100"
      stroke="#000"
      stroke-width="5"
      class="text-line"
      width="1000"
    >
      <text x="50%" dominant-baseline="middle" text-anchor="middle" y="50%">
        Reactslayer
      </text>
    </svg>
  );
};

export default Reactslayer;
