import React, { useRef, useEffect } from 'react';

import './style.scss';
import { motion } from 'framer-motion';
import { TweenMax, Power3 } from 'gsap';

import photoprofile from '../../assets/images/Ojq0zleOmPg.jpg';

const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96] };

const ProfileDescription = () => {
  let img = useRef(null);
  let p = useRef(null);

  useEffect(() => {
    TweenMax.staggerFrom(
      [p],
      2.5,
      { opacity: 0, y: 30, ease: Power3.easeOut },
      1
    );
    TweenMax.staggerFrom(
      [img],
      1.5,
      { opacity: 0, y: 50, ease: Power3.easeOut },
      1
    );
  }, []);
  return (
    <div>
      <div className="profile">
        <div className="container">
          <div className="wrapper">
            <div className="description">
              {/* <h4>This is the firct project</h4> */}
              <p
                className="small-title"
                ref={(element) => {
                  p = element;
                }}
              >
                <strong>
                  I am a Web Developer and Graphic Designer from Ukraine, living
                  in Kiev.I have interested in IT from 12 years and i have
                  gained a lot of practical experience in Photography, Graphic
                  Design, and Web Development.
                </strong>
                <br />
                <br /> This experience I have gained through different courses
                where I've teached, tones of tasks from me to get new knowledge
                and experience and many different projects for big company in
                Ukraine where i work in present days.
              </p>
            </div>
            <div
              className="box"
              ref={(element) => {
                img = element;
              }}
            >
              <motion.img
                className='imgLogoAbout'
                src={photoprofile}
                alt="photoprofile"
                transition={transition}
                whileHover={{ scale: 1.1 }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDescription;
